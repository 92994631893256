import React, { CSSProperties, PropsWithChildren, useEffect, useMemo } from "react";
import { useValeContext } from "../../system/ValeSystem";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Box, BoxProps, Theme } from "@mui/material";
import { BoxOwnProps } from "@mui/system/Box"
import { AuthPageType } from "../pages/pages_param_list";
import { HEADER_HEIGHT } from "../molecules/ValeHeader";

export type PageProps = PropsWithChildren<{
  containerStyle?: CSSProperties;
  requireAuth?: boolean | AuthPageType;
}>;

const Page: React.FC<PageProps> = ({
  children,
  containerStyle,
  requireAuth,
}) => {
  const vale = useValeContext();
  const navigation = useNavigation();
  const route = useRoute();

  useEffect(() => {
    if (requireAuth && vale?.firebaseAuth.currentUser === null) {
      const path = { name: route.name, params: route.params };
      navigation.navigate("auth", { redirectNav: btoa(JSON.stringify(path)), type: requireAuth });
    }
  }, []);

  const show = useMemo(() => {
    if (!requireAuth) return true;
    return vale?.firebaseAuth.currentUser !== null;
  }, [requireAuth, vale?.firebaseAuth?.currentUser]);

  return show ? (
    <div style={{ width: '100vw', ...containerStyle }}><div style={{
      height: HEADER_HEIGHT
    }}></div>{children}</div>
  ) : null;
};

export default Page;