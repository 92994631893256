import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { NextArrow } from "../atoms/next_arrow";

export type Props = {
  children: React.JSX.Element | string;
  onPress?: () => void;
  disabled?: boolean;
  width?: number | string;
  height?: number;
  id?: string;
  fontStyle?: React.CSSProperties;
  secondary?: boolean;
  disabledIfEmpty?: boolean;
  withArrow?: boolean;
  backgroundColor?: string;
  style?: React.CSSProperties; // New style prop
};

export const WIDTH = 194;
export const HEIGHT = 43;

export const DEFAULT_BUTTON_COLOR = "#8D55FF";
const DEFAULT_DROPSHADOW_COLOR = "#000000";
export const DISABLED_COLOR = "#6F6F70";

const ValeLaunchDisabledOverlay: React.FC = () => (
  <Box
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "#ffffffaa",
    }}
  />
);

const ValeButtonCustomize: React.FC<Props> = (props) => {
  const backgroundColor = props.backgroundColor
    ? props.backgroundColor
    : props.disabled
      ? "#E2E8F0"
      : props.secondary
        ? "#7C50FF"
        : "#FF00B8";

  const onClick = useCallback(() => {
    if (props.disabled) return;
    if (props.onPress) {
      props.onPress();
    }
  }, [props.disabled, props.onPress]);

  return (
    <Box
      id={props.id}
      width={props.width ?? WIDTH}
      height={props.height ?? HEIGHT}
      style={{ position: "relative", ...props.style }} // Apply custom style here
      onClick={onClick}
    >
      <Box
        style={{
          backgroundColor: "#2C2C2C",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 4,
          top: 5,
          borderRadius: 10,
        }}
      />
      <Box
        style={{
          backgroundColor: backgroundColor,
          borderColor: "#2C2C2C",
          borderStyle: "solid",
          borderWidth: 2,
          position: "absolute",
          bottom: 3,
          left: 2,
          right: 0,
          top: 0,
          borderRadius: 10,
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            textAlign: "center",
            width: "100%",
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: 12,
            ...props?.fontStyle,
          }}
        >
          {props.children}
          {props.withArrow ? (
            <>
              {" "}
              <NextArrow />
            </>
          ) : null}
        </div>
      </Box>
    </Box>
  );
};

export default ValeButtonCustomize;
