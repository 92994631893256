import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  LinkingOptions,
  NavigationContainer,
  getStateFromPath,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { useAuthContext } from "../../system/AuthProvider";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import PagesParamList from "../../components/pages/pages_param_list";
import navTheme from "../nav_theme";
import { OrderRoutes } from "./order/order_routes";
import AboutUsPage from "../../components/pages/about/AboutUsPage";
import LandingPage from "../../components/pages/landing/LandingPage";
import HomePage from "../../components/pages/home/HomePage";
import { CheckoutRoutes } from "./checkout/checkout_routes";
import SettingsPage from "../../components/pages/settings/SettingsPage";
import AuthScreen from "../../components/pages/auth/AuthScreen";
import { Linking } from "react-native";
import { processUserReferralCode } from "../../system/referrals";
import { useValeContext } from "../../system/ValeSystem";
import ValeHeader from "../../components/molecules/ValeHeader";
import MenuModal from "../../components/pages/menu/MenuModal";
import LoadingPage from "../../components/pages/auth/LoadingPage";
import AccountPage from "../../components/pages/home/account/AccountPage";
import HoursPage from "../../components/pages/about/HoursPage";
import { useGetActiveOrders } from "../../components/hooks/orderHooks";
import ContactPage from "../../components/pages/about/ContactPage";
import FeedbackPopup from "../../components/pages/about/FeedbackPopup";
import LoyaltyRewards from "../../components/pages/loyalty/LoyaltyRewards";
import AccountToggle from "../../components/pages/home/account/AccountToggle";
import LoyaltyRewardHome from "../../components/pages/loyalty/LoyaltyRewardHome";
import MenuTest from "../../components/pages/scratch/MenuTest";
import Vibes from "../../components/pages/home/account/Vibes";

const RootStackNavigator = createNativeStackNavigator<PagesParamList>();

const linking: LinkingOptions<PagesParamList> = {
  prefixes: [
    "https://valenow.com, http://localhost:3000",
    "https://coffee-c59f9.web.app",
  ],
  getStateFromPath: (path, options) => {
    const ret = getStateFromPath(path, options);

    //We use to use this to handle redirects, now we use initial url method

    return ret;
  },
};

const PageRoutes = () => {
  const user = useAuthContext();
  const vale = useValeContext();
  const activeOrders = useGetActiveOrders();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const navigationRef = useNavigationContainerRef();
  const [initialRouteName, setInitialRouteName] = useState<
    keyof PagesParamList | undefined
  >();

  useEffect(() => {
    /*
    ToDo : This take into account if they have placed an order already. If not they should go through the normal flow
    */
    Linking.getInitialURL().then((url) => {
      console.log("getInitialURL url", url);

      if (url) {
        const parsedUrl = new URL(url);
        const path = parsedUrl.pathname;
        if (path == "/uref") {
          const uCode = parsedUrl.searchParams.get("u_code");
          processUserReferralCode(uCode!, "peer2peerV1", vale!);
        } else if (path == "/truck_menu_venice") {
          setInitialRouteName("order");
          return
        }
      }

      if (user) {


        if ((activeOrders?.length || 0) > 0)
          setInitialRouteName("home");
        else
          setInitialRouteName("landing");

      } else {
        setInitialRouteName("landing");
      }
    });
  }, []);

  const onMenuOpen = useCallback(() => {
    setSideMenuOpen(true)
  }, [])

  const mainDisplay = sideMenuOpen ? "none" : "flex";
  return initialRouteName ? (
    <>
      <div id={"nav_wrapper_div"} style={{ flex: 1, display: mainDisplay }}>
        <NavigationContainer theme={navTheme} ref={navigationRef} linking={linking}>
          <RootStackNavigator.Navigator
            initialRouteName={initialRouteName}
            screenOptions={{ header: (props) => <ValeHeader onMenuOpen={onMenuOpen} {...props} /> }}
          >
            <RootStackNavigator.Screen name="about" component={AboutUsPage} />
            <RootStackNavigator.Screen name="hours" component={HoursPage} />
            <RootStackNavigator.Screen name="contact" component={ContactPage} />
            <RootStackNavigator.Screen name="account" component={AccountPage} />
            <RootStackNavigator.Screen name="landing" component={LandingPage} />
            <RootStackNavigator.Screen name="order" component={OrderRoutes} />
            <RootStackNavigator.Screen name="checkout" component={CheckoutRoutes} />
            <RootStackNavigator.Screen name="auth" component={AuthScreen} />
            <RootStackNavigator.Screen name="home" component={HomePage} />
            <RootStackNavigator.Screen name="settings" component={SettingsPage} />
            <RootStackNavigator.Screen name="devtest" component={LoyaltyRewardHome} />
            <RootStackNavigator.Screen name="menutest" component={MenuTest} />
            <RootStackNavigator.Screen name="vibes" component={Vibes} />
          </RootStackNavigator.Navigator>
        </NavigationContainer>
      </div>

      <MenuModal navigationRef={navigationRef} modalIsOpen={sideMenuOpen} onMenuClose={() => { setSideMenuOpen(false) }} />
    </>
  ) : null;
};

export default PageRoutes;

/*
      <RootStackNavigator.Navigator screenOptions={{ headerShown: false }}>
        <RootStackNavigator.Screen name="order" component={OrderRoutes} />
        <RootStackNavigator.Screen name="home" component={HomeRoutes} />
        <RootStackNavigator.Screen
          name="non_auth"
          component={NonAuthedRoutes}
        />
        <RootStackNavigator.Screen
          options={{
            presentation: "transparentModal",
            headerShown: false,
            animation: "fade",
          }}
          name="error_modal"
          component={ErrorModalPage}
        />
      </RootStackNavigator.Navigator>
      */
