import React, { useEffect, useMemo, useRef, useState } from "react";
import Page from "../../../templates/Page";
import MyAccountImage from "./MyAccountImage";
import Footer from "../../../molecules/footer";
import AccountToggle, { AccountPageType } from "./AccountToggle";
import Profile from "./Profile";
import Vibes from "./Vibes";
import { AnimatePresence, motion } from "framer-motion";
import { PageTitle } from "../../../atoms/TextStyles";

const w = '100vw'
const negW = '-100vw'
const style = {
  display: 'flex',
  justifyContent: 'center',
}
const AccountPage = () => {
  const [toggle, setToggle] = useState<AccountPageType>(AccountPageType.Profile)
  const [contentHeight, setContentHeight] = useState(0)
  const profileContentRef = useRef<HTMLDivElement>(null);
  const vibesContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = toggle ? vibesContentRef.current : profileContentRef.current
    console.log("current : ", current)
    const resizeObserver = new ResizeObserver(entries => {
      if (entries[0]) {
        const newHeight = entries[0].contentRect.height;
        console.log('newHeight : ', newHeight)
        setContentHeight(newHeight);
      }
    });


    if (current) {
      resizeObserver.observe(current);
    }

    return () => {
      if (current) {
        resizeObserver.unobserve(current);
      }
    };
  }, [profileContentRef.current, vibesContentRef.current, toggle]);

  const profileVariants = {
    hidden: { x: '-100vw', opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exit: { x: '-100vw', opacity: 0 }
  };

  const vibesVariants = {
    hidden: { x: '100vw', opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exit: { x: '100vw', opacity: 0 }
  };

  return (
    <Page requireAuth={'login'}>
      <PageTitle style={{ marginBottom: "40px" }}>YOUR ACCOUNT</PageTitle>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '-30px', zIndex: 1 }}>
        <AccountToggle onToggle={setToggle} />
      </div>
      <MyAccountImage />
      <div style={{ width: '100vw', height: contentHeight, position: 'relative', overflow: 'hidden' }}>
        <AnimatePresence >
          {!toggle ? (
            <motion.div
              key="profile"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={profileVariants}
              transition={{ ease: "linear" }}
              style={{ position: 'absolute', width: '100%' }}
              ref={profileContentRef}
            >
              <Profile />
            </motion.div>
          ) : (
            <motion.div
              key="vibes"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={vibesVariants}
              transition={{ ease: "linear" }}
              style={{ position: 'absolute', width: '100%' }}
              ref={vibesContentRef}
            >
              <Vibes />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Footer />
    </Page >
  );
};



export default AccountPage;
