import { Box } from "@mui/material";
import { forwardRef, PropsWithChildren } from "react";

export const promptPageModalComProps = {
    root: {
        style: {
            height: "100%"
        }
    },
    backdrop: {
        style: {
            backgroundColor: 'transparent'

        }
    }
}

type PromptPageProps = {
    onCloseButtonClicked: () => void;
} & PropsWithChildren

// We must use a forward ref here because this is going to be used as the child modal of a modal
const PromptModalPage = forwardRef<HTMLDivElement, PromptPageProps>((props) => {
    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{
                height: 80
            }}></div>
            <div style={{ flex: 1, position: "relative", backgroundColor: "#FF00B8", borderTopLeftRadius: "16px", borderTopRightRadius: "16px", borderRight: "2px #2C2C2C solid", borderLeft: "2px #2C2C2C solid", borderTop: "2px #2C2C2C solid" }}>
                {props.children}
                <div onClick={props.onCloseButtonClicked} style={{ position: 'absolute', top: 25, left: 25, }}>
                    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.377 1L2 11L11.377 21" stroke="#2C2C2C" strokeWidth="2" />
                        <path d="M24 12C24.5523 12 25 11.5523 25 11C25 10.4477 24.5523 10 24 10V12ZM2 12H24V10H2V12Z" fill="#2C2C2C" />
                    </svg>
                </div>
            </div>
        </div>
    )
})

export default PromptModalPage;