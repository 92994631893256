import React from "react";
import { Box, Typography } from "@mui/material";
import { StyleSheet } from "react-native";
import { Title } from "react-native-paper";
import { PageSubtitle, PageTitle } from "../../atoms/TextStyles";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";

const LearnHowBox = () => {

  return (
    <div style={styles.mainBox}>
      <div style={{ width: '50%', position: 'relative', display: "inline-block" }}>
        <div style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}>
          <img src="./images/rewards-drink.png" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
      </div>
      <center style={{ width: '50%', display: "inline-block", marginTop: 16, marginBottom: 16 }}>
        <div style={{ fontFamily: "BN Dime Display", fontSize: 26, fontWeight: 400, color: "#2C2C2C" }}>EARN VIBES,<br />GET FREE<br />STUFF</div>

        <div style={{}}>
          <ValeLaunchButton
            width={135}
            height={46}
            fontStyle={{ fontSize: 16 }}
            withArrow={true}
          >
            LEARN HOW
          </ValeLaunchButton>
        </div>
      </center>
    </div>
  );
};

export default LearnHowBox;

const styles = StyleSheet.create({

  mainBox: {
    backgroundColor: "#FFFA77",
    border: "2px solid #2C2C2C",
    display: 'flex',
  },


});