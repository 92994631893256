import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Box, Modal } from "@mui/material";
import { getEnv } from "../../../../../system/env";
import ValeLaunchButton from "../../../../molecules/ValeLaunchButton";
import { useValeContext } from "../../../../../system/ValeSystem";
import { tempAssetPost } from "../../../../../system/ValeCustomClient";
import Cropper, { Area } from "react-easy-crop";
import getCroppedImg from "../../../../utils/cropImage";
import PromptModalPage from "../../../modal/PromptModalPage";


export const promptPageModalComProps = {
  root: {
    style: {
      height: "100%",
      width: "100%",
    }
  },
  backdrop: {
    style: {
      backgroundColor: 'transparent'
    }
  }
}

const env = getEnv();
//const show = env.type === "localhost" || env.type === "dev";

export enum Mode {
  NONE,
  IMAGE_UPLOAD,
  GENERATIVE_TEXT,
}

type ImageCropperProps = { dataURL: string };
type ImageCropperForwardProps = {
  getCropped: () => Promise<Blob | null>;
};

const ImageCropper = forwardRef<ImageCropperForwardProps, ImageCropperProps>(
  (props, ref) => {
    //React.FC<{ dataURL: string }> = (props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
      console.log(croppedArea, croppedAreaPixels);
      setCroppedAreaPixels(croppedAreaPixels);
    };
    useImperativeHandle(
      ref,
      () => ({
        getCropped: async () => {
          const res = await getCroppedImg(props.dataURL, croppedAreaPixels, 0, {
            horizontal: false,
            vertical: false,
          });
          return res;
        },
      }),
      [props.dataURL, croppedAreaPixels],
    );

    return (
      <div style={{ width: "100%", height: 250 }}>
        <Cropper
          style={{ containerStyle: { width: "100%" } }}
          image={props.dataURL}
          crop={crop}
          zoom={zoom}
          aspect={1.31}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    );
  },
);

export type Props = {
  onLabelUpdated?: (imageId: string | undefined) => void;
  modalOpen: boolean;
  onClose: () => void;
};

type OrderLabelCustomizationPageProps = {
  onClose: () => void
  onLabelUpdated?: (imageId: string | undefined) => void;
}

const OrderLabelCustomizationPage: React.FC<OrderLabelCustomizationPageProps> = (props) => {
  const [image, setImage] = React.useState<FileReader | null>(null);
  const [busy, setBusy] = useState(false);
  const system = useValeContext();
  const cropperRef = useRef<ImageCropperForwardProps>(null);
  const [postUploadImage, setPostUploadImage] = React.useState<string>();

  useEffect(() => {

    // Create an input element
    const inputElement = document.getElementById('customLabelInputField') as HTMLInputElement //document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*,image/heic'; // Accept only image files

    // Add an event listener to handle the file selection
    inputElement.addEventListener('change', (event) => {
      // @ts-ignore
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImage(e.target);
        }
        reader.onerror = (e) => {
          console.error(e);
          alert('An error occurred while reading the file.');
          inputElement.remove
        }
        reader.readAsDataURL(file);
      } else {
        alert('Please select a valid image file.');
      }
    });
    console.log('sending click to element  : ', inputElement)

    // Trigger the file input element to open the file picker dialog
    inputElement.click();
  }, []);

  return <PromptModalPage onCloseButtonClicked={() => {
    props.onClose?.();
    setImage(null);
  }}>
    <div style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between", }}>
      <div style={{ flex: 1 }}>
        <ImageCropper
          dataURL={image?.result as string}
          ref={cropperRef}
        />
      </div>
      <input type="file" id="customLabelInputField" accept='image/*,image/heic' style={{ display: 'none' }} />
      <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: 8 }}>
        <ValeLaunchButton
          disabled={busy}
          onPress={async () => {
            try {
              if (image) {
                setBusy(true);
                const croppedImage =
                  await cropperRef.current?.getCropped();

                const imageId = await tempAssetPost(
                  system!,
                  croppedImage!,
                );
                props.onClose?.();

                setPostUploadImage(
                  URL.createObjectURL(croppedImage!),
                );

                setImage(null);

                props.onLabelUpdated?.(imageId);
              }
            } catch (e) {
              console.error(e);
            }
            setBusy(false);
          }}
        >
          UPLOAD
        </ValeLaunchButton>
      </div>
    </div>
  </PromptModalPage>
}

const OrderLabelCustomizationModal: React.FC<Props> = (props) => {
  return <Modal componentsProps={promptPageModalComProps} open={props.modalOpen} >
    <Box style={{
      height: "100%",
      width: "100%",
    }}>
      <OrderLabelCustomizationPage onLabelUpdated={props.onLabelUpdated} onClose={props.onClose} />
    </Box>
  </Modal>
};

export default OrderLabelCustomizationModal;


const itemClearStyle = {
  color: "black",
  fontSize: 24,
  fontFamily: "Inter",
  fontWeight: "500",
  lineHeight: 16,
  borderWidth: 1,
  padding: 4,
}