import React from "react";
import Page from '../../templates/Page';
import { Box, Typography } from "@mui/material";
import { PageTitle } from "../../atoms/TextStyles";
import { StyleSheet } from "react-native";
import AccountToggle from "../home/account/AccountToggle";
import { Title } from "react-native-paper";
import { useSessionContext } from "../../../system/SessionProvider";
import { Components } from "../../../vale_common";

type RewardBoxProps = {
  user?: Components.Schemas.UserRecord
  vibePoints?: number
}

const RewardBox: React.FC<RewardBoxProps> = (props) => {

  const { user, vibePoints } = props

  return (
    <>
      <div style={styles.mainBox}>
        <img src="./images/reward-yellow.png" style={styles.yellow} />
        <img src="./images/reward-can.png" style={styles.can} />

        <PageTitle style={styles.title}>HEY,<br />{user?.displayName}</PageTitle>


        <Box style={styles.pointBox}>
          <Typography style={{ color: "white", textAlign: "center", "fontFamily": "Inter", fontWeight: 700, paddingTop: "6px", marginBottom: "5px" }}>YOUR VIBE STATUS</Typography>
          <Typography style={styles.myPoints}>{vibePoints}</Typography>
          <Typography style={{ color: "white", textAlign: "center", paddingBottom: "5px" }}>$1 = 10 vibes</Typography>
        </Box>

        <Typography style={{ color: "#2C2C2C", fontSize: 12, width: "35%", textAlign: "center", "fontFamily": "Inter", fontWeight: 600, marginLeft: "50px", marginTop: "25px", marginBottom: "20px" }}>Your can fills up with Vibes for every purchase and task. 🎉 </Typography>

      </div>

    </>
  );
};

export default RewardBox;

const styles = StyleSheet.create({

  mainBox: {
    backgroundColor: "#EDE0FF",
    width: '95%',
    height: '360px',
    left: 0,
    top: 0,
    margin: "auto",
    border: "2px solid #2C2C2C",
    borderRadius: 7,
    overflow: "hidden",
    marginBottom: "10px",
    position: "relative"
  },
  yellow: {
    maxWidth: "100%",
  },
  title: {
    position: "absolute",
    top: 20,
    left: 10,
    width: "50%",
    fontSize: "32px",
    lineHeight: "32px",
  },
  can: {
    width: "160px",
    position: "absolute",
    top: 20,
    right: 20,
  },
  pointBox: {
    backgroundColor: "#FF00B8",
    width: "48%",
    marginLeft: "20px",
    borderRadius: 8,
    marginTop: "-10px"
  },
  myPoints: {
    fontFamily: "BN Dime Display",
    textAlign: "center",
    fontSize: 36,
    fontWeight: "400",
    color: "#FFFA77",
    lineHeight: 1,
    marginBottom: "5px"

  }

});