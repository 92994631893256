import React from "react";
import { RewardCard } from "../RewardComponent";

const DanceRewardCard: React.FC = () => {

    const onPress = () => {

    }

    return <RewardCard
        name={"Lycra Makeover"}
        imageUrl={'./images/loyalty/loyalty_dance.jpg'}
        onPress={onPress}
        locked={true}
    />
}

export default DanceRewardCard;