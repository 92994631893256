import React from "react";
import { Box, Typography } from "@mui/material";
import { StyleSheet } from "react-native";
import { PageSubtitle, PageTitle } from "../../atoms/TextStyles";
import RewardComponent from "./RewardComponent";
import DrinkRewardCard from "./awards/DrinkRewardCard";
import FrisbeeRewardCard from "./awards/FrisbeeRewardCard";
import JetSkiRewardCard from "./awards/JetSkiRewardCard";
import DanceRewardCard from "./awards/DanceRewardCard";

type Props = {
  currentPoints: number | undefined;
}
const OmgRewardsBox: React.FC<Props> = (props) => {
  const { currentPoints } = props;

  return (
    <div style={styles.background}>
      <div style={styles.mainBox}>


        <div style={styles.headerBox}>
          <Typography style={{ width: "99%", paddingTop: "20px" }}
            color="#2c2c2c" textAlign={'center'} fontSize={32} fontFamily={"BN Dime Display"} lineHeight={'36px'} fontWeight={400}>
            THE VIBE STORE
          </Typography>

          <PageSubtitle style={{ color: "#2c2c2c", marginTop: "10px", marginBottom: "25px" }}>Unlock rewards by cashing<br />in your Vibes 🤑</PageSubtitle>
        </div>

        {/* TODO: make dynamic later */}
        <RewardComponent
          level={1}
          points={400}
          currentPoints={currentPoints}
          firstReward={<DrinkRewardCard currentPoints={currentPoints} points={400} />}
          secondReward={<FrisbeeRewardCard currentPoints={currentPoints} points={400} />}
        />
        {
          <RewardComponent
            level={2}
            points={10000000}
            currentPoints={currentPoints}
            firstReward={<JetSkiRewardCard />}
            secondReward={<DanceRewardCard />}
          />}
      </div>
    </div>
  );
};

export default OmgRewardsBox;

const styles = StyleSheet.create({
  background: {
    width: "100%",
    backgroundColor: "#F8B2FF",
    paddingTop: "50px",
  },
  mainBox: {
    width: "90%",
    paddingBottom: "10px",
    backgroundColor: "white",
    left: 0,
    right: 0,
    margin: "auto",
    border: "2px solid #2C2C2C",
    borderRadius: 7,

  },
  headerBox: {
    backgroundColor: "#FFFA77",
    borderRadius: 7,
  }
});