import { Typography } from '@mui/material';
import React, { CSSProperties, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import ValeButtonCustomize from '../../molecules/ValeButtonCustomize';
import PromptModalPage from '../modal/PromptModalPage';
import { NextArrow } from '../../atoms/next_arrow';
const borderWidth = 2
const imageMargin = 22
interface Reward {
    name: string;
    imageUrl: string;
    onPress?: () => void
}

interface RewardComponentProps {
    level: number;
    points: number;
    currentPoints: number | undefined;
    firstReward: ReactNode;
    secondReward: ReactNode;
}

export type OhMyStarsProps = {
    contanierStyle?: CSSProperties | undefined;
    text: string

}
export const OhMyStars: React.FC<OhMyStarsProps> = (props) => {
    return <div style={{ ...styles.topBar, ...props.contanierStyle }}>
        <span style={styles.star}>⭐️</span>
        <span style={styles.star}>⭐️</span>
        <span style={styles.star}>⭐️</span>
        <span style={styles.star}>⭐️</span>
        <span style={styles.star}>⭐️</span>
        <span style={styles.level}>{props.text}</span>
        <span style={styles.star}>⭐️</span>
        <span style={styles.star}>⭐️</span>
        <span style={styles.star}>⭐️</span>
        <span style={styles.star}>⭐️</span>
        <span style={styles.star}>⭐️</span>
    </div>
}
const RewardComponent: React.FC<RewardComponentProps> = ({ level, points, firstReward, secondReward }) => {
    return (
        <div style={styles.container}>
            <OhMyStars text={`LEVEL ${level}`} />

            <Typography style={styles.cost}>{points} Vibes</Typography>

            <div style={{ width: "50%", display: "inline-block" }}>
                {firstReward}
            </div>
            <div style={{ width: "50%", display: "inline-block" }}>
                {secondReward}
            </div>


        </div>
    );
};

export type RewardCardProps = {
    name: string;
    imageUrl: string;
    onPress?: () => void
    locked?: boolean
}

export const RewardCard: React.FC<RewardCardProps> = (props) => {
    const [cardWidth, setCardWidth] = useState<number>()
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const resizeObserver = new ResizeObserver(entries => {
            if (entries[0]) {
                const newWidth = entries[0].contentRect.width;
                console.log('newWidth', newWidth)
                setCardWidth(newWidth);
            }
        });


        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                resizeObserver.unobserve(divRef.current);
            }
        };
    }, [divRef.current]);


    const backgroundColor = props.locked ? '#FF00B8' : '#7C50FF'
    const textColor = props.locked ? 'black' : 'white'

    const svg = useMemo(() => {
        console.log('cardWidth', cardWidth)
        if (!cardWidth) {
            return null
        }

        const center = (cardWidth / 2);
        const radius = center - imageMargin;
        const circumference = 2 * Math.PI * radius;
        return <svg width={cardWidth} height={cardWidth} viewBox={`0 0 ${cardWidth} ${cardWidth}`} preserveAspectRatio="xMidYMid meet">
            <defs>
                <path id="circlePath" d={`M ${center}, ${center} m -${radius}, 0 a ${radius},${radius} 0 1,1 ${2 * radius},0 a ${radius},${radius} 0 1,1 -${2 * radius},0`} />
            </defs>
            <text fill="#2C2C2C" style={{ fontFamily: 'BN Dime Display', fontSize: '22px', fontWeight: '400' }}>
                <textPath href="#circlePath" startOffset="25%" textAnchor="middle">
                    {props.name?.toUpperCase?.()}
                </textPath>
            </text>
        </svg>

    }, [cardWidth, props.name])

    return <div style={{ position: 'relative', width: "100%", backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} ref={divRef}>
            {svg}
        </div>
        <div style={styles.imgBorder}>
            <img src={props.imageUrl} alt={props.name} style={styles.rewardImage} />
        </div>
        <center style={{ height: 40, width: '100%' }}>
            <ValeButtonCustomize
                onPress={props.onPress}
                width={"90%"}
                height={43}
                fontStyle={{ fontSize: 15, fontWeight: 700 }}
                backgroundColor={backgroundColor}
                style={{ color: textColor, top: "-20px", minWidth: 120 }}
            >
                {props.locked ? '🔒 LOCKED' : <><span>REDEEM ME!{"  "}</span><NextArrow /></>}
            </ValeButtonCustomize>
        </center>
    </div >
};


const styles: { [key: string]: React.CSSProperties } = {


    container: {
        backgroundColor: "white",
        paddingBottom: 24
    },
    topBar: {
        backgroundColor: "#FF00B8",
        height: "38px",
        lineHeight: "38px",
        color: "white",
        fontFamily: "Inter",
        fontWeight: 600,
        fontSize: "18px",
        textAlign: "center"
    },
    star: {
        padding: "0 1%",
    },
    level: {
        padding: "0 2%",
    },
    cost: {
        color: "#F64AC3",
        textAlign: "center",
        fontFamily: "Inter",
        fontWeight: 600,
        marginTop: "10px"

    },
    rewardCardsContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "0px",
    },
    rewardCard: {
        //width: '200px',
        //backgroundColor: "#ffffff",
        padding: "16px",
        textAlign: "center",
    },
    rewardImage: {
        // width: "100%",
        // height: "auto",
        // borderRadius: "4px",
        // marginBottom: "8px",
        width: '100%',
        height: 'auto'
    },
    imgBorder: {
        height: 'auto',
        overflow: 'hidden',
        borderRadius: '50%', /* This makes the div a circle */
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: borderWidth,
        borderStyle: 'solid',
        margin: imageMargin,
        borderColor: "#2C2C2C"
    },
};

export default RewardComponent;
