import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { amountToString } from "../components/utils/stringConversion";
import { CartItem } from "./CartItem";
import { v4 as uuidv4 } from 'uuid';
import { useSessionContext } from "./SessionProvider";
import { findMenuItem, preLoadLabelImage } from "../components/utils/orderItemUtils";
import { useNoSessionContext } from "./NoSessionProvider";
import { useValeContext } from "./ValeSystem";
import _ from "lodash";
import { User } from "firebase/auth";

const CART_KEY = 'cart';
const CURRENT_CART_VERSION = 7
const TEMPLATE_NUMS = 4
export const sumMenuItem = (items: CartItem[]) => {
    const newTotalInt = (items ?? []).reduce((acc, item) => {
        if (item.paymentVariant === 'vibes')
            return acc || 0
        return acc + (item?.price?.amount || 0);
    }, 0)

    return newTotalInt
}

export type OrderContextType = {
    addItem: (item: CartItem) => string;
    removeItem: (id: string) => void
    updateItem: (item: Partial<CartItem>) => void;
    setOrderName?: (name: string) => void;
    setPromoCode?: (name?: string | null) => void;
    clear: () => void;
    items: CartItem[];
    totalCents?: number;
    orderName?: string;
    promoCode?: string;
}

export const CartContext = React.createContext<OrderContextType | null>(null);
export const useCartContext = () => useContext(CartContext);

type CartLocalStorage = {
    items: CartItem[];
    orderName?: string;
    totalCents?: number;
    version: number;
    promoCode?: string;
}

const CartProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const system = useValeContext();
    const [items, setItems] = React.useState<CartItem[]>([]);
    const [orderName, setOrderName] = React.useState<string>();
    const [promoCode, setPromoCode] = React.useState<string>();
    const [totalCents, setTotalCents] = React.useState<number>();
    const session = useSessionContext();
    const lastUserState = useRef<User | null | undefined>();

    const clear = useCallback(() => {
        setItems([]);
        setTotalCents(undefined);
        setOrderName(undefined);
        localStorage.removeItem(CART_KEY);
        setPromoCode(undefined);
    }, [])

    useEffect(() => {
        //First time add callbacks
        system?.firebaseAuth.onAuthStateChanged(async (user) => {
            if (lastUserState.current === undefined) {
                lastUserState.current = user;
                return
            }

            //Handle Auth Status Changes
            //clear cart if user was logged in and now is not
            if (lastUserState.current && !user) {
                clear();
            }
            lastUserState.current = user;
        });
    }, []);

    useEffect(() => {
        if (orderName || session?.userData?.userRecord?.displayName) {
            setItems(items => {
                let withChange = false
                const updatedItems = items.map(item => {
                    if (!item.customerName) {
                        withChange = true;
                        return { ...item, customerName: orderName ?? session?.userData?.userRecord?.displayName }
                    }
                    return item
                })
                if (withChange) {
                    localStorage.setItem(CART_KEY, JSON.stringify({ items: updatedItems, totalCents, orderName, promoCode, version: CURRENT_CART_VERSION }));
                    return updatedItems
                }
                return items
            })
        }
    }, [session?.userData?.userRecord?.displayName, orderName, totalCents, items, promoCode])

    useEffect(() => {
        //Load items from local storage
        //This is useful for testing, but maybe we want to keep it long term
        const value = localStorage.getItem(CART_KEY);

        if (value) {
            const cart: CartLocalStorage = JSON.parse(value);
            if (cart) {
                if (cart.version !== CURRENT_CART_VERSION) {
                    console.warn('Cart version mismatch, clearing cart')
                    localStorage.removeItem(CART_KEY);
                    return
                }

                setItems(cart.items);

                if (cart.orderName) {
                    setOrderName(cart.orderName);
                }

                setTotalCents(cart.totalCents);

                for (const item of cart.items) {
                    preLoadLabelImage(item.customerName!, item?.name!, item.labelTemplateNumber!, item.labelImageId);
                }

                if (cart.promoCode) {
                    setPromoCode(cart.promoCode);
                }
            }
        }
    }, []);



    const onSetOrderName = useCallback((orderName: string) => {
        setOrderName(orderName)
        localStorage.setItem(CART_KEY, JSON.stringify({ items, totalCents, orderName, version: CURRENT_CART_VERSION, promoCode }));
    }, [items, totalCents, orderName, promoCode])

    const onSetPromoCode = useCallback((promoCode: string) => {
        setPromoCode(promoCode)
        localStorage.setItem(CART_KEY, JSON.stringify({ items, totalCents, orderName, version: CURRENT_CART_VERSION, promoCode }));
    }, [items, totalCents, orderName, promoCode])

    const addItem = useCallback((item: CartItem) => {
        const tempItemId = uuidv4();
        const newItem = { ...item, clientItemId: tempItemId, itemTypeVariationId: item.itemTypeVariationId, itemTypeId: item.itemTypeId }

        if (orderName || session?.userData?.userRecord?.displayName) {
            newItem.customerName = orderName ?? session?.userData?.userRecord?.displayName;
        }

        const labelTemplateNumber = Math.floor(Math.random() * TEMPLATE_NUMS) + 1;

        newItem.labelTemplateNumber = labelTemplateNumber

        items.push(newItem);
        const totalCents = sumMenuItem(items);

        setTotalCents(totalCents)
        setItems([...items]);
        localStorage.setItem(CART_KEY, JSON.stringify({ items, totalCents, promoCode, orderName, version: CURRENT_CART_VERSION }));

        preLoadLabelImage(newItem.customerName!, newItem.name, newItem.labelTemplateNumber);
        return tempItemId
    }, [items, orderName, promoCode, session?.userData?.userRecord?.displayName]);

    const removeItem = useCallback((tempItemId: string) => {
        const itemIdx = items.findIndex(item => item.clientItemId == tempItemId)
        if (itemIdx !== -1) {
            items.splice(itemIdx, 1);
            setItems([...items]);
        }

        const totalCents = sumMenuItem(items);
        setTotalCents(totalCents)
        localStorage.setItem(CART_KEY, JSON.stringify({ items, totalCents, orderName, promoCode, version: CURRENT_CART_VERSION }));
    }, [items, orderName, promoCode])

    const updateItem = useCallback((exisitingItem: Partial<CartItem>) => {

        const itemIdx = items.findIndex(item => item.clientItemId == exisitingItem.clientItemId)
        const oldItem = items[itemIdx]
        const newItem = { ...oldItem, ...exisitingItem }
        items[itemIdx] = newItem
        setItems([...items]);

        preLoadLabelImage(newItem.customerName!, newItem.name!, newItem.labelTemplateNumber!, newItem.labelImageId);
        localStorage.setItem(CART_KEY, JSON.stringify({ items, total: totalCents, orderName, promoCode, version: CURRENT_CART_VERSION }));
    }, [items, orderName, promoCode, system?.firebaseAuth?.currentUser?.uid])

    const value = { clear, setOrderName: onSetOrderName, items, addItem, orderName, totalCents, removeItem, updateItem, setPromoCode: onSetPromoCode, promoCode };

    return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export default CartProvider;