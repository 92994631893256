import React from 'react';
import Page from '../../templates/Page';
import { RewardCard } from '../loyalty/RewardComponent';

const MenuTest: React.FC = () => {

    return (
        <Page>
            <RewardCard
                name={'Free Drink'}
                imageUrl={'./images/loyalty/loyalty_freedrink.jpg'}
                onPress={() => { }}
            />
        </Page>
    );
};

export default MenuTest;