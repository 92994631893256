import React, { useState } from "react";
import Page from "../../../templates/Page";
import Footer from '../../../molecules/footer';
import { motion } from "framer-motion";

export enum AccountPageType {
  Profile,
  Vibes
}

export type AccountToggleProps = {
  onToggle: (newValue: AccountPageType) => void
}

const AccountToggle: React.FC<AccountToggleProps> = (props) => {
  const [toggle, setToggle] = useState<AccountPageType>(AccountPageType.Profile)
  const selStyle = toggle === AccountPageType.Vibes ? { left: undefined, right: 0 } : { left: 0, right: undefined }
  const styles: { [key: string]: React.CSSProperties } = {
    toggleContainer: {
      height: "54px",
      backgroundColor: "#FF00B8",
      width: "350px",
      border: "3px solid #2C2C2C",
      borderRadius: "27px",
      position: "relative",
      overflow: "hidden",
    },
    selected: {
      top: 0,
      bottom: 0,
      position: 'absolute',
      backgroundColor: "#FFFA77",
      width: "50%",
      borderTopRightRadius: "27px",
      borderBottomRightRadius: "27px",
      borderTopLeftRadius: "27px",
      borderBottomLeftRadius: "27px",
      zIndex: 0,
      ...selStyle,
    },
    labels: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: "flex",
      justifyContent: "space-around",
      alignItems: 'center',
      position: 'absolute',

      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '21.78px'
    }
  };

  return (
    <div style={styles.toggleContainer} onClick={() => {
      const newValue = toggle === AccountPageType.Vibes ? AccountPageType.Profile : AccountPageType.Vibes
      setToggle(newValue)
      props.onToggle(newValue)
    }
    }>
      <motion.div style={styles.selected} layout />
      <div style={styles.labels}>
        <div>MY PROFILE</div>
        <div>MY VIBES</div>
      </div>
    </div>
  );
};

export default AccountToggle;
