import { Box, Container, Typography } from "@mui/material";
import Page from "../../../../templates/Page";
import { PageTitle } from "../../../../atoms/TextStyles";
import { OrderContextType, useCartContext } from "../../../../../system/CartProvider";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { groupBy } from "lodash";
import { CartItem } from "../../../../../system/CartItem";
import { useNoSessionContext } from "../../../../../system/NoSessionProvider";
import { getOverrideImage } from "./menu_overrides";
import { amountToString } from "../../../../utils/stringConversion";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useValeStackNavigation } from "../../../../../nav/nav_utils";
import { findMenuItem } from "../../../../utils/orderItemUtils";
import CheckoutBar, { shouldReviewOrder } from "./CheckoutBar";
import { FOOT_PADDING } from "../../../../molecules/footer";
import { Title } from "react-native-paper";
import ValeLaunchButton from "../../../../molecules/ValeLaunchButton";
import { useGoToStartOrder } from "../../../landing/landing_page_utils";
import { MenuItem } from "../../../../../vale_common";
import { useToPaymentPage } from "../../../checkout/checkout_process";
import PromoCodeComponent from "./PromoCodeComponent";
import Lottie from "react-lottie";
import * as eyeAnimation from "../../../checkout/animations/eyes.json"
import { OhMyStars } from "../../../loyalty/RewardComponent";

type GroupTitleProps = {
    name?: string;
    description?: string;
}

type OnChangeNameCB = (tempItemId: string) => void

const GroupTitle: FC<GroupTitleProps> = (props) => {
    return <Box width={200} minHeight={100}>
        <Typography lineHeight={"28px"} color="#FF00B8" fontFamily="BN Dime Display" fontSize={28}>{props.name?.toUpperCase()}</Typography>
        <Typography color="#4712B1" fontWeight={500} fontSize={14}>{props.description}</Typography>
    </Box>
}

type SummaryTileProps = {
    items: CartItem[]
    menuItem: MenuItem | undefined;
    onMore: () => void
}

const SummaryTile: FC<SummaryTileProps> = (props) => {

    const itemCost = amountToString(props.menuItem?.variations?.[0]?.price?.amount)


    return (
        <Box >
            <Box display="flex" alignItems="center" justifyContent={"flex-end"}>
                <Typography color="#2C2C2C" fontSize={14} fontWeight={600}>{props.items?.length}x&nbsp;</Typography>
                <Typography color="#FF00B8" fontSize={18} fontWeight={700} >{itemCost}</Typography>
            </Box>
            <Box onClick={props.onMore} display="flex" alignItems="center" justifyContent={"flex-end"}>
                <AddCircleIcon fontSize={'large'} htmlColor="#FF00B8" /><Typography fontWeight={600} fontSize={12} color="#FF00B8">ADD<br />MORE</Typography>
            </Box>
        </Box>
    )
}

type ItemGroupProps = {
    menuItem: MenuItem | undefined;
    items: CartItem[];
    cart: OrderContextType;
    defaultCustomerName?: string;
    onChangeName: OnChangeNameCB;
}

type VibeItemProps = {
    menuItem: MenuItem | undefined;
    item: CartItem;
    cart: OrderContextType;
    defaultCustomerName?: string;
    onChangeName: OnChangeNameCB;
}

type ItemProps = {
    item: CartItem;
    cart: OrderContextType;
    onChangeName: OnChangeNameCB;
}


const Item: FC<ItemProps> = (props) => {
    const onRemove = useCallback(() => {
        props.cart?.removeItem(props.item?.clientItemId!)
    }, [props.cart?.removeItem])
    return <>
        <div style={{ marginTop: "24px" }}>
            <Typography color="#4712B1" fontSize={12} fontWeight={500} marginBottom={"8px"}>This drink is for:</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div onClick={() => props.onChangeName(props.item?.clientItemId!)} style={{ position: "relative", borderBottom: "2px #FF00B8 solid", height: "32px", flex: 1, marginRight: "24px" }}>
                <Typography color="#FF00B8" fontSize={16} fontWeight={500}>{props.item.customerName}</Typography>
                <div style={{ position: "absolute", top: 0, right: 8 }} >
                    <Typography fontSize={16} fontWeight={500}>✏️</Typography>
                </div>
            </div>
            <Typography onClick={onRemove} color="#2C2C2C" fontSize={10} fontWeight={500} textAlign={"center"}>x<br />remove</Typography>
        </div>
    </>
}

const VibeItem: FC<VibeItemProps> = (props) => {
    const { item, menuItem, cart, onChangeName, defaultCustomerName } = props
    const imageUrl = getOverrideImage(menuItem?.name) ?? menuItem?.imageUrl;


    return <Box marginTop={"45px"}
    ><Box display="flex" width={"100%"} id="boxGroup123">
            <Box width={"110px"} height={"195px"}>
                {imageUrl ? <img src={imageUrl} style={{ width: '100%', height: 'auto', display: 'block', alignSelf: "center" }} /> : null}
            </Box>
            <Box width={"100%"} marginRight={"24px"}>
                <Box flex={1} width={"100%"} display="flex" justifyContent={"space-between"}>
                    <GroupTitle name={menuItem?.name} description={menuItem?.description} />
                </Box>
                <Item key={item.clientItemId} cart={cart} onChangeName={onChangeName} item={{ customerName: defaultCustomerName, ...item }} />
            </Box>
        </Box>
    </Box>
}

const ItemGroup: FC<ItemGroupProps> = (props) => {

    const imageUrl = getOverrideImage(props.menuItem?.name) ?? props.menuItem?.imageUrl;

    const orders = props.items.map((item) => {
        return <Item key={item.clientItemId} cart={props.cart} onChangeName={props.onChangeName} item={{ customerName: props.defaultCustomerName, ...item }} />
    });

    const onMore = useCallback(() => {
        if (props.menuItem) {
            const cartItem: CartItem = { itemTypeId: props.menuItem.id!, itemTypeVariationId: props.menuItem.variations![0].id!, price: props.menuItem.variations?.[0].price || {}, name: props.menuItem.name! }
            props.cart.addItem(cartItem)
        }
    }, [props.cart, props.menuItem])

    return <Box marginTop={"45px"}
    ><Box display="flex" width={"100%"} id="boxGroup123">
            <Box width={"110px"} height={"195px"}>
                {imageUrl ? <img src={imageUrl} style={{ width: '100%', height: 'auto', display: 'block', alignSelf: "center" }} /> : null}
            </Box>
            <Box width={"100%"} marginRight={"24px"}>
                <Box flex={1} width={"100%"} display="flex" justifyContent={"space-between"}>
                    <GroupTitle name={props.menuItem?.name} description={props.menuItem?.description} />
                    <SummaryTile items={props.items} onMore={onMore} menuItem={props.menuItem} />
                </Box>
                {orders}
            </Box>
        </Box>
    </Box>
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: eyeAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function emptyCart() {
    const cart = useCartContext();
    const itemCount = cart?.items?.length;

    const goToStartOrder = useGoToStartOrder();

    if (itemCount && itemCount > 0) return;

    return (
        <>
            <center>


                <Box
                    sx={{ width: 200, height: 200 }}
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                >
                    <Lottie options={defaultOptions}
                        width={200}
                        height={200}
                    />
                </Box>
                <Typography color="#2C2C2C" fontFamily={"BN Dime Display"} fontSize={30} fontWeight={500}>
                    IT'S EMPTY HERE
                </Typography>
                <Typography color="#4712B1" marginBottom={"40px"} fontFamily={"Inter"} fontSize={16} fontWeight={600}>
                    These drinks aren't going to<br />drink themselves! 😂
                </Typography>

                <ValeLaunchButton
                    width={225}
                    height={70}
                    fontStyle={{ fontSize: 18 }}
                    onPress={goToStartOrder}
                >
                    START YOUR ORDER
                </ValeLaunchButton>

            </center>

        </>
    );
}

function promoCode() {

    const cart = useCartContext();
    const itemCount = cart?.items?.length;

    if (!itemCount) return;

    return (
        <PromoCodeComponent />
    );

}

const CartPage: FC = () => {
    const cart = useCartContext();
    const noSessionInfo = useNoSessionContext();
    const itemCount = cart?.items?.length
    const navigation = useValeStackNavigation();

    const onChangeName = useCallback(async (tempItemId: string) => {
        navigation.push("order", {
            screen: "cart_item_edit", params: {
                clientItemId: tempItemId
            }
        })
    }, [])

    const onBack = useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate("order", { screen: "selection" })
        }
    }, []);

    const itemGroups = useMemo(() => {
        if (!noSessionInfo?.menuItems) {
            return null
        }
        const moneyItems: CartItem[] = []
        const vibeItems: CartItem[] = []

        cart?.items.forEach(item => {
            if (item.paymentVariant === 'vibes') {
                vibeItems.push(item);
            } else {
                moneyItems.push(item);
            }
        });
        //group by item type
        const itemTypeIdGroups = groupBy(moneyItems, "itemTypeId")
        const itemTypeIdGroupsSorted = Object.keys(itemTypeIdGroups).sort()
        const defaultCustomerName = cart?.orderName

        const ret = itemTypeIdGroupsSorted.map((itemTypeId) => {
            const menuItem = findMenuItem(noSessionInfo?.menuItems, itemTypeId)
            return <ItemGroup defaultCustomerName={defaultCustomerName} key={itemTypeId} menuItem={menuItem} items={itemTypeIdGroups[itemTypeId]} cart={cart!} onChangeName={onChangeName} />
        })

        if (vibeItems?.length > 0) {
            ret.push(<OhMyStars text="Vibe Items" contanierStyle={{ marginTop: 16 }} />)
            vibeItems.forEach(item => {
                const menuItem = findMenuItem(noSessionInfo?.menuItems, item.itemTypeId)
                ret.push(<VibeItem defaultCustomerName={defaultCustomerName} key={item.clientItemId} menuItem={menuItem} item={item} cart={cart!} onChangeName={onChangeName} />)
            })
        }
        return ret
    }, [cart, noSessionInfo?.menuItems, onChangeName, cart?.orderName]);
    const toPaymentPage = useToPaymentPage();
    const onCheckOut = useCallback(() => {
        const reviewOrder = shouldReviewOrder(cart, noSessionInfo?.menuItems)
        if (reviewOrder) {
            navigation.push("order", { screen: "review", params: {} })
            return
        }

        toPaymentPage()
        return

    }, [navigation, cart, noSessionInfo?.menuItems, toPaymentPage])

    return (
        <Page >
            <div style={{ flex: 1, paddingBottom: FOOT_PADDING }}>
                <div style={{
                    backgroundColor: "#DECDFF",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: -1
                }} />
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                    <div onClick={onBack} style={{ width: "50px", height: "40px", marginTop: "", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.377 1L2 11L11.377 21" stroke="#2C2C2C" strokeWidth="2" />
                            <path d="M24 12C24.5523 12 25 11.5523 25 11C25 10.4477 24.5523 10 24 10V12ZM2 12H24V10H2V12Z" fill="#2C2C2C" />
                        </svg>
                    </div>
                    <PageTitle>YOUR BAG</PageTitle>
                    <Typography fontFamily={"BN Dime Display"} fontSize={24} color="#E30A8F">({itemCount})</Typography>
                    <div style={{ width: "50px", height: "50px" }} />
                </div>
                <div style={{ paddingBottom: "100px" }}>
                    {emptyCart()}
                    {itemGroups}
                </div>

                {promoCode()}

            </div>
            <CheckoutBar onCheckout={onCheckOut} />
        </Page>
    );
}

export default CartPage 