import React from "react";
import { RewardCard } from "../RewardComponent";

const JetSkiRewardCard: React.FC = () => {

    const onPress = () => {

    }

    return <RewardCard
        name={"Used 80's Jet Ski"}
        imageUrl={'./images/loyalty/loyalty_ski.jpg'}
        onPress={onPress}
        locked={true}
    />
}

export default JetSkiRewardCard;