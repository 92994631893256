import React, { useCallback, useState } from "react";
import { RewardCard } from "../RewardComponent";
import { Modal } from "@mui/material";
import PromptModalPage from "../../modal/PromptModalPage";
import SelectionView from "../../home/menu/selection/SelectionList";
import { useCartContext } from "../../../../system/CartProvider";
import { MenuItem } from "../../../../vale_common";
import { useValeStackNavigation } from "../../../../nav/nav_utils";


type DrinkRewardCardProps = {
    currentPoints: number | undefined
    points: number
}

const DrinkRewardCard: React.FC<DrinkRewardCardProps> = (props) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const cart = useCartContext();
    const navigation = useValeStackNavigation();

    const onSelection = useCallback(async (menuItem: MenuItem) => {
        cart?.addItem({ paymentVariant: 'vibes', itemTypeId: menuItem.id!, itemTypeVariationId: menuItem.variations![0].id!, price: menuItem.variations?.[0].price || {}, name: menuItem.name! });
        setMenuIsOpen(false);
        navigation.push("order", { screen: "cart", params: {} })
        return true;
    }, [cart!.orderName, cart?.setOrderName, cart?.addItem])

    const locked: boolean = (props.currentPoints || 0) < 400
    return <><RewardCard
        name={'Free Drink'}
        imageUrl={'./images/loyalty/loyalty_freedrink.jpg'}
        onPress={() => setMenuIsOpen(true)}
        locked={locked}
    />
        <Modal open={menuIsOpen}>
            <PromptModalPage onCloseButtonClicked={() => { setMenuIsOpen(false) }}>
                <div style={{ overflowY: 'scroll', maxHeight: '95vh' }}>
                    <SelectionView onAdd={onSelection} hideFree={true} hidePrice={true} />
                </div>
            </PromptModalPage>
        </Modal>
    </>
}

export default DrinkRewardCard;