import React, { useEffect, useState } from 'react'
import RewardBox from '../../loyalty/RewardBox';
import NextRewardComp from '../../loyalty/NextRewardComp';
import LearnHowBox from '../../loyalty/LearnHowBox';
import OmgRewardsBox from '../../loyalty/OmgRewardsBox';
import { useSessionContext } from '../../../../system/SessionProvider';
import { useValeContext } from '../../../../system/ValeSystem';

const Vibes: React.FC = () => {

    const system = useValeContext();
    const session = useSessionContext();
    const user = session?.userData?.userRecord;
    const [vibePoints, setVibePoints] = useState<number | undefined>()
    useEffect(() => {
        system?.valeClientOpenapi?.userVibes().then((resp) => {
            setVibePoints(resp.data.vibePoints)
        })
    }, [])

    return <div style={{ backgroundColor: '#FF00B8' }}>
        <RewardBox user={user} vibePoints={vibePoints} />

        <NextRewardComp />

        <LearnHowBox />

        <OmgRewardsBox currentPoints={vibePoints} />
    </div>
}

export default Vibes;
